var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("e-store-combo", {
                        attrs: {
                          id: "order-store_id",
                          required: false,
                          label: _vm.$t("Loja"),
                        },
                        model: {
                          value: _vm.filters.storeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "storeId", $$v)
                          },
                          expression: "filters.storeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "status",
                          type: "vue-select",
                          label: _vm.$t("Status"),
                          options: _vm.approvalFlowStatusOptions,
                          placeholder: _vm.$t("Todos"),
                        },
                        model: {
                          value: _vm.filters.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "status", $$v)
                          },
                          expression: "filters.status",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "status",
                          type: "vue-select",
                          label: _vm.$t("Tipo de Fluxo"),
                          options: [
                            {
                              label: _vm.$t(
                                "ApprovalFlow.Type.PurchaseOrderFinish"
                              ),
                              value: "PurchaseOrderFinish",
                            },
                            {
                              label: _vm.$t("ApprovalFlow.Type.ServiceRequest"),
                              value: "ServiceRequest",
                            },
                          ],
                          placeholder: _vm.$t("Todos"),
                        },
                        model: {
                          value: _vm.filters.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "type", $$v)
                          },
                          expression: "filters.type",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          name: "name",
                          type: "text",
                          disabled: !_vm.filters.type,
                          label: _vm.objectIdLabel,
                          placeholder: _vm.objectIdPlaceholder,
                        },
                        model: {
                          value: _vm.filters.entityId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "entityId", $$v)
                          },
                          expression: "filters.entityId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          name: "name",
                          type: "switch",
                          label: _vm.$t("Pendentes para mim"),
                        },
                        model: {
                          value: _vm.filters.pendingForMe,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "pendingForMe", $$v)
                          },
                          expression: "filters.pendingForMe",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "users-table",
            staticClass: "bordered",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(_vm.$tc("USER.NAME")),
              fields: _vm.fields,
              items: _vm.approvalFlows,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c("e-grid-actions", {
                      attrs: {
                        "show-read-only": _vm.$can("Update", "ApprovalFlow"),
                        "show-update": false,
                        "show-delete": false,
                      },
                      on: {
                        "read-only": function ($event) {
                          return _vm.showApprovalFlowDetails(row)
                        },
                      },
                    }),
                  ]
                },
              },
              {
                key: "cell(status)",
                fn: function (ref) {
                  var value = ref.value
                  return [
                    _c("e-status-badge", {
                      attrs: {
                        status: value,
                        options: _vm.approvalFlowStatusOptions,
                      },
                    }),
                  ]
                },
              },
              {
                key: "cell(stores)",
                fn: function (data) {
                  return [
                    data.item.allowAllStores
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("Todas")))])
                      : _c("label", [
                          _vm._v(
                            _vm._s(
                              data.item.stores
                                .map(function (i) {
                                  return i.id
                                })
                                .join(", ")
                            )
                          ),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            { staticClass: "pt-1" },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "user-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.$can("Create", "ApprovalFlow")
        ? _c("fab", {
            attrs: { "main-tooltip": _vm.$t("Adicionar usuário") },
            on: { click: _vm.showCreateApprovalFlowSidebar },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }