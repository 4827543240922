<template>
  <div>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="3">
            <e-store-combo
              id="order-store_id"
              v-model="filters.storeId"
              :required="false"
              :label="$t('Loja')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="status"
              v-model="filters.status"
              type="vue-select"
              :label="$t('Status')"
              :options="approvalFlowStatusOptions"
              :placeholder="$t('Todos')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="status"
              v-model="filters.type"
              type="vue-select"
              :label="$t('Tipo de Fluxo')"
              :options="[
                {
                  label: $t('ApprovalFlow.Type.PurchaseOrderFinish'),
                  value: 'PurchaseOrderFinish',
                },
                {
                  label: $t('ApprovalFlow.Type.ServiceRequest'),
                  value: 'ServiceRequest',
                },
              ]"
              :placeholder="$t('Todos')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              v-model="filters.entityId"
              name="name"
              type="text"
              :disabled="!filters.type"
              :label="objectIdLabel"
              :placeholder="objectIdPlaceholder"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              v-model="filters.pendingForMe"
              name="name"
              type="switch"
              :label="$t('Pendentes para mim')"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>
      <b-table
        ref="users-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('USER.NAME'))"
        :fields="fields"
        :items="approvalFlows"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(action)="row">
          <e-grid-actions
            :show-read-only="$can('Update', 'ApprovalFlow')"
            :show-update="false"
            :show-delete="false"
            @read-only="showApprovalFlowDetails(row)"
          />
        </template>
        <template #cell(status)="{ value }">
          <e-status-badge
            :status="value"
            :options="approvalFlowStatusOptions"
          />
        </template>
        <template #cell(stores)="data">
          <span v-if="data.item.allowAllStores">{{ $t('Todas') }}</span>
          <label v-else>{{ data.item.stores.map((i) => i.id).join(', ') }}</label>
        </template>
      </b-table>

      <b-row class="pt-1">
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="user-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <fab
      v-if="$can('Create', 'ApprovalFlow')"
      :main-tooltip="$t('Adicionar usuário')"
      @click="showCreateApprovalFlowSidebar"
    />
  </div>
</template>

<script>
import { BTable, BPagination, BRow, BCol } from 'bootstrap-vue'
import fab from '@/views/components/FAB.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { EPaginationSummary, EPageSizeSelector } from '@/views/components'
import { stringUtils, approvalFlowDomains } from '@/mixins'
import EFilters from '@/views/components/EFilters.vue'
import EGridActions from '@/views/components/EGridActions.vue'
import { mapActions, mapState } from 'vuex'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'
import EStatusBadge from '@/views/components/EStatusBadge.vue'

export default {
  components: {
    BTable,
    BPagination,
    BRow,
    BCol,
    EPaginationSummary,
    EPageSizeSelector,
    BCardActions,
    fab,
    EFilters,
    EGridActions,
    EStoreCombo,
    EStatusBadge,
  },

  mixins: [stringUtils, approvalFlowDomains],

  data() {
    return {
      fetching: false,
      saving: false,
      showSidebar: false,
      edit: false,
      showHistory: false,
      tenant: '',
      // user: getInitialSecurityApprovalFlowForm(),
    }
  },

  computed: {
    ...mapState('app', ['stores']),
    ...mapState('pages/security/approvalFlowTransaction', [
      'approvalFlows',
      'filters',
      'paging',
      'sorting',
    ]),

    objectIdLabel() {
      if (this.filters.type === 'PurchaseOrderFinish') {
        return this.$t('Id do Pedido')
      }
      if (this.filters.type === 'ServiceRequest') {
        return this.$t('Id da Solicitação')
      }
      return this.$t('Id do Objeto de Aprovação')
    },

    objectIdPlaceholder() {
      if (this.filters.type === 'PurchaseOrderFinish') {
        return this.$t('Id do Pedido')
      }
      if (this.filters.type === 'ServiceRequest') {
        return this.$t('Id da Solicitação')
      }

      return this.$t('Selecione o Tipo de Fluxo')
    },

    fields() {
      return [
        {
          label: this.$t('Ação'),
          key: 'action',
          class: 'text-center',
          thStyle: 'width: 80px',
        },
        {
          label: this.$t('#'),
          key: 'id',
          sortable: true,
          tdClass: 'text-right',
          thStyle: 'width: 90px',
        },
        {
          label: this.$t('Fluxo'),
          key: 'name',
          // class: 'text-center',
        },
        {
          label: this.$t('Objeto de Aprovação'),
          key: 'object',
          // class: 'text-center',
          sortable: true,
          formatter: (value, index, item) =>
            `${this.$t(`ApprovalFlow.Type.${item.type}`)}: #${item.entityId}`,
        },
        {
          label: this.$t('Status'),
          key: 'status',
          class: 'text-center',
          sortable: true,
          thStyle: 'width: 160px',
        },
        {
          label: this.$t('Criado em'),
          key: 'createdDate',
          class: 'text-center',
          thStyle: 'width: 120px',
          sortable: true,
          formatter: value => this.$options.filters.datetime(value),
        },
      ]
    },
  },

  mounted() {
    this.getData()
    // this.$store.dispatch('pages/security/accessProfiles/fetchallAccessProfiles')
    // this.accessProfiles()
  },

  methods: {
    ...mapActions('pages/security/approvalFlowTransaction', [
      'setApprovalFlow',
      'cleanApprovalFlowState',
      'fetchApprovalFlows',
      'setCurrentPage',
      'setPageSize',
      'resetFilters',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchApprovalFlows()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },
    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    async showCreateApprovalFlowSidebar() {
      await this.cleanApprovalFlowState()
      // this.user = getInitialSecurityApprovalFlowForm()
      this.edit = false
      this.showSidebar = true
    },
    onHideSidebar() {
      this.cleanApprovalFlowState()
      this.showSidebar = false
    },

    async showApprovalFlowDetails(row) {
      this.$router.push({
        name: 'approval-flow-details',
        params: { id: row.item.id },
      })
    },

    async saveApprovalFlow() {
      if (await this.$refs.userForm.validate()) {
        try {
          this.saving = true

          const storesUpdated = [...this.user.stores]
          const storesSelected = [...this.user.storesSelected]
          const storesApi = storesUpdated.map(store => {
            const result = { ...store }
            if (!storesSelected.some(storeId => storeId === store.id.toString())) {
              result.isDeleted = true
            }
            return result
          })
          const newStores = storesSelected
            .filter(storeId => !storesApi.some(store => store.id.toString() === storeId))
            .map(storeId => ({ id: storeId }))

          const body = {
            id: this.user.id,
            active: this.user.active,
            name: this.user.name,
            document: this.user.document,
            userName: this.user.userName,
            email: this.user.email,
            allowAllStores: this.user.allowAllStores,
            password: this.user.password,
            stores: [...storesApi, ...newStores],
            accessProfileNames: this.user.accessProfilesSelected,
          }
          await this.$http({
            url: '/api/users',
            method: this.edit ? 'PUT' : 'POST',
            data: body,
          })
          this.showSuccess({
            message: this.$t(`${this.edit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
          })
          this.showSidebar = false
          this.getData()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.saving = false
        }
      } else {
        this.showInvalidDataMessage()
      }
    },
  },
}
</script>
